/*
 * Defaults
 *
 * Resetting of browser defaults
 */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/*
 * Margins
 *
 * marginc    margin collapsible: responsively changes
 */

.margin-t-24 {
  margin-top: 24px;
}

.margin-t-48 {
  margin-top: 48px;
}

.margin-t-96 {
  margin-top: 96px;
}

.margin-t-240 {
  margin-top: 240px;
}

.marginc-t-240 {
  margin-top: 240px;
}

.marginc-t-320 {
  margin-top: 320px;
}

.marginc-t-400 {
  margin-top: 400px;
}

.margin-r-8 {
  margin-right: 8px;
}

.margin-r-16 {
  margin-right: 16px;
}

.margin-r-24 {
  margin-right: 24px;
}

.margin-r-32 {
  margin-right: 32px;
}

.margin-r-36,
.marginc-r-36 {
  margin-right: 36px;
}

.margin-r-48,
.marginc-r-48 {
  margin-right: 48px;
}

.margin-t-row-1 {
  margin-top: calc(100% / 12 * 1);
}

.margin-t-row-2 {
  margin-top: calc(100% / 12 * 2);
}

.margin-l-24 {
  margin-left: 24px;
}

.margin-l-col-1,
.marginc-l-col-1 {
  margin-left: calc(100% / 12 * 1);
}

.margin-l-col-2,
.marginc-l-col-2 {
  margin-left: calc(100% / 12 * 2);
}

.margin-l-col-3,
.marginc-l-col-3 {
  margin-left: calc(100% / 12 * 3);
}

.margin-l-col-4,
.marginc-l-col-4 {
  margin-left: calc(100% / 12 * 4);
}

.margin-l-col-5,
.marginc-l-col-5 {
  margin-left: calc(100% / 12 * 5);
}

.margin-l-col-6,
.marginc-l-col-6 {
  margin-left: calc(100% / 12 * 6);
}

.margin-l-col-7,
.marginc-l-col-7 {
  margin-left: calc(100% / 12 * 7);
}

.margin-l-col-8,
.marginc-l-col-8 {
  margin-left: calc(100% / 12 * 8);
}

.margin-l-col-9,
.marginc-l-col-9 {
  margin-left: calc(100% / 12 * 9);
}

.margin-b-24 {
  margin-bottom: 24px;
}

.margin-b-96 {
  margin-bottom: 96px;
}

.margin-b-row-1 {
  margin-bottom: calc(100% / 12 * 1);
}

.margin-b-row-2 {
  margin-bottom: calc(100% / 12 * 2);
}

.margin-b-row-3 {
  margin-bottom: calc(100% / 12 * 3);
}

.margin-b-row-4 {
  margin-bottom: calc(100% / 12 * 4);
}

.margin-h-col-2 {
  margin-left: calc(100% / 12 * 2);
  margin-right: calc(100% / 12 * 2);
}

.paddingc-h-24,
.padding-h-24 {
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
}

.padding-h-36 {
  box-sizing: border-box;
  padding-left: 36px;
  padding-right: 36px;
}

.padding-h-col-1 {
  box-sizing: border-box;
  padding-left: calc(100% / 12 * 1);
  padding-right: calc(100% / 12 * 1);
}

.padding-8 {
  box-sizing: border-box;
  padding: 8px;
}

.padding-v-8 {
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-v-row-1 {
  box-sizing: border-box;
  padding-top: calc(100% / 12 * 1);
  padding-bottom: calc(100% / 12 * 1);
}

.padding-v-24 {
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 24px;
}

.padding-v-36 {
  box-sizing: border-box;
  padding-top: 36px;
  padding-bottom: 36px;
}

.padding-12 {
  box-sizing: border-box;
  padding: 12px;
}

.padding-24 {
  box-sizing: border-box;
  padding: 24px;
}

.padding-36 {
  box-sizing: border-box;
  padding: 36px;
}

@media only screen and (max-width: 768px) {
  .marginc-l-col-1,
  .marginc-l-col-2,
  .marginc-l-col-3,
  .marginc-l-col-4,
  .marginc-l-col-5,
  .marginc-l-col-6,
  .marginc-l-col-7,
  .marginc-l-col-8,
  .marginc-l-col-9 {
    margin-left: 0;
  }
  .marginc-t-240,
  .marginc-t-320,
  .marginc-t-400 {
    margin-top: initial;
  }

  .marginc-r-36,
  .marginc-r-48 {
    margin-right: 0;
    margin-bottom: 24px;
  }

  .paddingc-h-24 {
    padding: 0;
  }
}

/*
 * Positioning
 *
 */

.pc-absolute,
.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-sticky {
  position: sticky;
}

.p-fixed {
  position: fixed;
}

.pin-top {
  top: 0;
}

.pin-bottom {
  bottom: 0;
}

.pin-top-left {
  top: 0;
  left: 0;
}

.pin-top-right {
  top: 0;
  right: 0;
}

.pin-bottom-left {
  bottom: 0;
  left: 0;
}

.pin-bottom-right {
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .pc-absolute {
    position: initial;
  }
}

/*
 * Flex 12 col Layout System
 *
 */

.cell {
  position: relative;
  padding: 16px;
  display: flex;
  box-sizing: border-box;
}

.cell.center {
  align-items: center;
}

.row,
.rowc {
  display: flex;
  flex-direction: row;
}

.row-1 {
  height: calc(100% / 12);
}

.row-2 {
  height: calc(100% / 12 * 2);
}

.row-3 {
  height: calc(100% / 12 * 3);
}

.row-4 {
  height: calc(100% / 12 * 4);
}

.row-5 {
  height: calc(100% / 12 * 5);
}

.row-6 {
  height: calc(100% / 12 * 6);
}

.row-7 {
  height: calc(100% / 12 * 7);
}

.row-8 {
  height: calc(100% / 12 * 8);
}

.row-9 {
  height: calc(100% / 12 * 9);
}

.row-10 {
  height: calc(100% / 12 * 10);
}

.row-11 {
  height: calc(100% / 12 * 11);
}

.row-12 {
  height: calc(100% / 12 * 12);
}

.f-wrap {
  flex-wrap: wrap;
}

.f-initial,
.col.f-initial,
.row.f-initial,
.rowc.f-initial {
  flex: initial;
}

.f-shrink-no {
  flex-shrink: 0;
}

.a-center {
  align-items: center;
}

.a-end {
  align-items: flex-end;
}

.a-self-end {
  align-self: flex-end;
}

.j-center {
  justify-content: center;
}

.j-between {
  justify-content: space-between;
}

.j-end {
  justify-content: flex-end;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.col-1 {
  flex-basis: calc(100% / 12);
  flex-grow: 0;
  flex-shrink: 0;
}

.col-2 {
  flex-basis: calc(100% / 12 * 2);
  flex-grow: 0;
  flex-shrink: 0;
}

.col-3 {
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
}

.col-4 {
  flex-basis: calc(100% / 3);
  flex-grow: 0;
  flex-shrink: 0;
}

.col-5 {
  flex-basis: calc(100% / 12 * 5);
  flex-grow: 0;
  flex-shrink: 0;
}

.col-6 {
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
}

.col-7 {
  flex-basis: calc(100% / 12 * 7);
  flex-grow: 0;
  flex-shrink: 0;
}

.col-8 {
  flex-basis: calc(100% / 3 * 2);
  flex-grow: 0;
  flex-shrink: 0;
}

.col-9 {
  flex-basis: 75%;
  flex-grow: 0;
  flex-shrink: 0;
}

.col-10 {
  flex-basis: calc(100% / 12 * 10);
  flex-grow: 0;
  flex-shrink: 0;
}

.col-11 {
  flex-basis: calc(100% / 12 * 11);
  flex-grow: 0;
  flex-shrink: 0;
}

.col-12 {
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

.col-thin {
  flex-basis: 24px;
  flex-grow: 0;
  flex-shrink: 0;
}

.flex-1 {
  flex-grow: 1;
  flex-basis: 0;
}

.flex-1-5 {
  flex-grow: 1.5;
  flex-basis: 0;
}

.border-b {
  border-bottom: var(--border-weight) var(--border-style);
}

.border-r {
  border-right: var(--border-weight) var(--border-style);
}

.border-l {
  border-left: var(--border-weight) var(--border-style);
}

@media only screen and (max-width: 768px) {
  .cell {
    padding: 12px;
  }
  .rowc {
    flex-direction: column;
  }
  .rowc.center {
    align-items: flex-start;
  }
  .rowc .border-r {
    border-right: none;
    border-bottom: 2px solid black;
  }
  .rowc .border-l {
    border-left: none;
    border-bottom: 2px solid black;
  }
  .rowc .col-2,
  .rowc .col-thin,
  .rowc .col-3,
  .rowc .col-9 {
    width: initial;
  }
  .rowc .margin-r-24 {
    margin-bottom: 24px;
    margin-right: 0;
  }

  .rowc .flex-1-5,
  .rowc .flex-1 {
    flex-basis: auto;
  }
}

/*
 * Sizing
 *
 */

.fwc-25vw {
  flex: 0 0 25vw;
}

.fwc-50vw,
.fw-50vw {
  flex: 0 0 50vw;
}

.fw-100vw {
  flex: 0 0 100vw;
}

.fw-200vw {
  flex: 0 0 200vw;
}

.fwc-256 {
  flex: 0 0 256px;
}

.fw-512,
.fwc-512 {
  flex: 0 0 512px;
}

.fw-768 {
  flex: 0 0 768px;
}

@media only screen and (max-width: 768px) {
  .fwc-25vw,
  .fwc-50vw {
    flex: 0 0 100%;
  }

  .fwc-512,
  .fwc-768 {
    flex: 0 0 100%;
  }
}

/*
 * Text styles
 *
 */

.w100 {
  font-weight: 300;
}
.w200 {
  font-weight: 300;
}
.w300 {
  font-weight: 300;
}
.w400 {
  font-weight: 400;
}
.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.w800 {
  font-weight: 800;
}
.w900 {
  font-weight: 900;
}

.light {
  font-weight: 200;
}
.medium {
  font-weight: 500;
}
.semi-bold {
  font-weight: 600;
}

.caps {
  text-transform: uppercase;
}

.text-stroke {
  /*text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,*/
  /*1px 1px 0 white;*/
  -webkit-text-stroke: 1px var(--foreground);
  color: transparent;
}

.text-a-center {
  text-align: center;
}

.text-a-right {
  text-align: right;
}

/*
 * Components
 *
 */

.close-button {
  width: 48px;
  height: 48px;
  align-self: center;
}

.close-button > svg > path {
  fill: white;
}

span.m-dash {
  border-top: 1px solid var(--text-color);
  width: 2.5ch;
  display: inline-block;
  margin-left: 1px;
}

span.long-m-dash {
  border-top: 1px solid var(--text-color);
  width: 4ch;
  display: inline-block;
  transform: translateY(calc(0.5px - 0.5ch));
}

.color-cover,
.color-cover-trans {
  background: var(--cover-background);
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.color-cover-trans {
  opacity: 1;
  will-change: opacity;
  transition: opacity 0.3s cubic-bezier(0.24, 1, 0.32, 1) 0s;
}

.color-cover-trans:hover {
  opacity: 0;
}

.color-cover + .img,
.color-cover-trans + .img {
  filter: grayscale(1);
}

.color-cover-trans {
  transition: filter 0.3s cubic-bezier(0.24, 1, 0.32, 1) 0s;
  will-change: filter;
}

.color-cover-trans:hover + .img {
  filter: grayscale(0);
}

.color-cover + video,
.color-cover-trans + video {
  filter: grayscale(1) blur(5px);
}

.color-cover-trans + video {
  transform: scale(1.02);
  transition: filter 0.3s cubic-bezier(0.24, 1, 0.32, 1) 0s,
    transform 0.3s cubic-bezier(0.24, 1, 0.32, 1) 0s;
  will-change: filter transform;
}

.color-cover-trans:hover + video {
  filter: grayscale(0);
  transform: scale(1.05);
}

/*
 * Misc
 *
 */

.img {
  object-fit: cover;
  max-width: 100%;
}

.img.vert {
  object-fit: cover;
  max-height: 100%;
  max-width: initial;
}

.vertical-text,
.vertical-textc,
.vertical-text-r,
.vertical-textc-r {
  writing-mode: vertical-rl;
  overflow: initial;
  white-space: nowrap;
  transform: rotate(180deg);
  text-align: right;
}
.vertical-text-r,
.vertical-textc-r {
  text-align: left;
  transform: none;
}

.on-load-fade-in {
  /* invisible until loaded */
  transform: translateZ(0.1px);
  transition: opacity 0.9s ease-out, margin-top 0.6s ease-out;
  margin-top: 36px;
  opacity: 0;
}
.on-load-fade-in.visible {
  opacity: 1;
  margin-top: 0;
}

.mobile-only {
  display: none;
}

@media only screen and (max-width: 768px) {
  .vertical-textc,
  .vertical-textc-r {
    transform: initial;
    writing-mode: initial;
    text-align: left;
  }

  .mobile-only {
    display: flex;
  }
}
