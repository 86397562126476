:root {
  --primary: red;
  --background: white;
  --foreground: black;
  --timing-f: 0.3s cubic-bezier(0.24, 1, 0.32, 1) 0s;
  --timing-f-slow: 0.6s cubic-bezier(0.24, 1, 0.32, 1) 0s;
}

::selection {
  background-color: rgba(0, 0, 0, 0.99);
}

html {
  font-family: 'Public Sans', sans-serif;
  background: var(--background);
  color: var(--foreground);
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}

.colw-12 {
  width: 100%;
}

.col-4-s {
  flex-basis: calc(100% / 3);
}

h1 {
  font-size: 48px;
}

.banner {
  font-size: 96px;
  line-height: 94%;
}

.x-large {
  transform: scale(1.2) translateY(-5px);
  display: inline-block;
}

.nameplate {
  color: var(--primary);
  font-size: 36px;
  font-weight: bold;
}

.pill {
  border-radius: 999px;
  background: var(--primary);
  color: var(--background);
  height: 60px;
  width: 140px;
}

.o-scroll {
  overflow: scroll;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: transform var(--timing-f);
}

.button:hover {
  transform: scale(1.03);
}

a {
  position: relative;
  transition: color var(--timing-f);
  text-decoration: none;
  color: var(--foreground);
}

a::after {
  content: '';
  position: absolute;
  height: 80%;
  top: 10%;
  width: 0;
  background: var(--primary);
  right: 0;
  transition: width var(--timing-f);
  z-index: -1;
}

a:hover {
  color: var(--background);
}

a:hover::after {
  width: 100%;
  left: 0;
}

a:visited {
  color: inherit;
}

#container {
  z-index: -1;
}
#container > canvas {
  position: fixed;
  z-index: -1;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity var(--timing-f-slow);
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity var(--timing-f-slow);
}

.page-container {
  min-height: 100vh;
  overflow: hidden;
}

td {
  vertical-align: top;
}

thead {
  text-align: left;
  text-transform: uppercase;
  opacity: 0.6;
  font-size: smaller;
}

input {
  min-width: 20vw;
  margin-bottom: 24px;
  height: 24px;
  font-size: 18px;
}

label {
  text-transform: uppercase;
  opacity: 0.6;
  font-size: smaller;
}

@media only screen and (max-width: 768px) {
  .banner {
    font-size: 64px;
  }
}
